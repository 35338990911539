/* eslint-disable react/jsx-fragments */
import React from 'react';
import { string, shape } from 'prop-types';
import { Script } from 'nordic/script';
import { LazyHydrate } from 'nordic/lazy';

import Section from '../../../commons/section';
import withTracker from '../../../commons/with-tracker';
import { POM_ADN_ID } from '../../recos-landings/shared/constants';

const namespace = 'advertising';

function AdvertisingAdn(props) {
  const { id, content } = props;
  const [isBannerEmpty, setIsBannerEmpty] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const handleBannerEmpty = () => setIsBannerEmpty(true);
    const node = ref.current;

    if (node) {
      node.addEventListener('adn-banner-empty', handleBannerEmpty);
    }

    return () => {
      if (node) {
        node.removeEventListener('adn-banner-empty', handleBannerEmpty);
      }
    };
  }, []);

  return (
    <Section type="adn" className={id === POM_ADN_ID ? `${namespace}-pom-line-${!isBannerEmpty}` : 'AD_ADN_area'}>
      <Script src="https://http2.mlstatic.com/frontend-assets/adn-frontend-library/adn-script.js" />
      <div ref={ref}>
        <LazyHydrate ssrOnly>
          <div
            data-component="adn"
            data-content={JSON.stringify(content)}
          />
        </LazyHydrate>
      </div>
    </Section>
  );
}

AdvertisingAdn.propTypes = {
  id: string,
  content: shape({
    category: string,
    page: string,
    placement: string,
    platform: string,
    site_id: string,
    slot_id: string,
    title: string,
  }).isRequired,
};

export default withTracker(AdvertisingAdn);
